import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text
} from '@chakra-ui/react';
import { OutlineButton } from 'components/Buttons/Buttons';
import { INotification } from '../../types/index';
import { AuthContext } from 'context/AuthContext';
import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import itunu_logo from '../../assets/svg/itunu_logo.svg';
import Sidebar from '../../components/DashboardLayout/Sidebar';
import { Icon } from '../../components/Icons';
import { Headline } from '../../components/Texts/Texts';
import useDashboard from './useDashboard';
import { DashboardContext } from '../../context/DashboardContext';
import { colors } from '../../theme/colors';
import NotificationItem from '../../components/NotificationGroup/NotificationItem';

interface DashboardLayoutProps {
  children: any;
  pageTitle: string;
}

const DashboardLayout = ({ children, pageTitle }: DashboardLayoutProps) => {
  const { isLoading, currentUser } = useDashboard();
  const auth = useContext(AuthContext);
  const { notifications, notificationsCount, updateNotification } = useContext(DashboardContext);
  const observer = useRef<IntersectionObserver | null>(null);

  const groupNotifications = (notifications: INotification[]): Record<string, INotification[]> => {
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setDate(today.getDate() - 30);

    const groups: Record<string, INotification[]> = {
      Today: [],
      ThisWeek: [],
      ThisMonth: [],
      Earlier: []
    };

    notifications?.forEach((notification) => {
      const notificationDate = new Date(notification.createdAt);
      if (notificationDate.toDateString() === today.toDateString()) {
        groups.Today.push(notification);
      } else if (notificationDate >= oneWeekAgo && notificationDate < today) {
        groups.ThisWeek.push(notification);
      } else if (notificationDate >= oneMonthAgo && notificationDate < oneWeekAgo) {
        groups.ThisMonth.push(notification);
      } else {
        groups.Earlier.push(notification);
      }
    });

    return groups;
  };

  const groupedNotifications = groupNotifications(notifications);

  const renderNotifications = (group: INotification[], title: string) => {
    if (group.length) {
      return (
        <Box my='16px'>
          <Text maxW='90%' mx='auto' my='8px' color='#05396B'>
            {title}
          </Text>
          {group.map((notification) => (
            <NotificationItem key={notification._id} date={notification.createdAt} notificationId={notification._id}
            >
              {notification.message}
            </NotificationItem>
          ))}

        </Box>
      );
    }
  };
  useEffect(() => {
      observer.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const notificationId = entry.target.getAttribute('data-notification-id');
            if (notificationId) {
              updateNotification(notificationId);
              observer.current?.unobserve(entry.target);
            }
        });
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    }
  );

  const notificationElements = document.querySelectorAll('[data-notification-id]');
  notificationElements.forEach((element) => {
    observer.current?.observe(element);
  });

  return () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };
}, [notifications]);

const navigate = useNavigate();
return (
  <Box as='main'>
    <Box
      bg='white'
      boxShadow='0px 4px 20px rgba(0, 0, 0, 0.05)'
      pos='fixed'
      zIndex={5}
      w='100%'
    >
      <Container maxW='container.xxl'>
        <Grid gridTemplateColumns='1fr 4fr' h='84px'>
          <Flex alignItems='center' h='100%'>
            <Image
              src={itunu_logo}
              h='56px'
              w='auto'
              flexShrink={0}
              ml='78px'
            />
          </Flex>
          <Flex alignItems='center' pr='50px' h='100%'>
            <Headline
              size='23px'
              color='deep_blue'
              fontWeight={700}
              mr='auto'
            >
              {pageTitle}
            </Headline>
            <HStack gap='30px'>
              <Box>
                <Popover>
                  <PopoverTrigger>
                    <Box cursor='pointer' pos={'relative'}>
                      <Icon name='Notification' filled />
                      {notificationsCount ? <Text display={'flex'} alignItems={'center'} justifyContent={'center'} pos={'absolute'}
                             top={'-10px'} left={'10px'} backgroundColor={'#FF7171'} color={'white'}
                             textAlign={'center'} fontWeight={'semibold'} fontSize={'12px'}
                             w={'20px'} h={'20px'} borderRadius={'2000%'}>{notificationsCount}</Text>:null}
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      background='#F9F9F9'
                      border='none'
                      mr='20px'
                      minW='392px'
                      boxShadow='rgba(149, 157, 165, 0.2) 0px 8px 24px;'
                      mt='30px'
                      _focus={{
                        outline: 'none'
                      }}
                    >
                      <PopoverHeader
                        px='24px'
                        py='16px'
                        border='none'
                        background='#FFF'
                      >
                        Your notifications
                      </PopoverHeader>
                      <PopoverCloseButton
                        my='15px'
                        borderRadius='40px'
                        p='5px'
                        mr='16px'
                        background='#DBE2EA'
                      />
                      <PopoverBody border='none' padding={0} maxH={'590px'} overflowX={'scroll'}>
                        {renderNotifications(groupedNotifications.Today, 'Today')}
                        {renderNotifications(groupedNotifications.ThisWeek, 'This Week')}
                        {renderNotifications(groupedNotifications.ThisMonth, 'This Month')}
                        {renderNotifications(groupedNotifications.Earlier, 'Earlier')}
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Box>
              <Box>
                <Popover>
                  <PopoverTrigger>
                    <Box cursor='pointer'>
                      <Icon name='Profile' filled />
                    </Box>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent
                      background='#F9F9F9'
                      border='none'
                      mr='20px'
                      minW='392px'
                      boxShadow='0 2px 4px #8888'
                      mt='30px'
                      _focus={{
                        outline: 'none'
                      }}
                    >
                      <PopoverHeader
                        px='24px'
                        py='16px'
                        border='none'
                        background='#FFF'
                      >
                        Your Profile
                      </PopoverHeader>
                      <PopoverCloseButton
                        my='15px'
                        borderRadius='40px'
                        p='5px'
                        mr='16px'
                        background='#DBE2EA'
                      />
                      <PopoverBody border='none' padding={0}>
                        <Flex
                          alignItems='center'
                          px='24px'
                          pb='16px'
                          pt='30px'
                          mb='16px'
                        >
                          <Flex
                            alignItems='center'
                            justifyContent='center'
                            mr='12px'
                            h='36px'
                            w='36px'
                            borderRadius='50%'
                            bg={colors.input_gray}
                          >
                            <Icon name='Profile' filled />
                          </Flex>
                          <Box>
                            <Text
                              color='#05396B'
                              fontWeight='500'
                              fontSize='16px'
                              mb='4px'
                            >
                              {currentUser.name}
                            </Text>
                            <Text
                              color='#05396B'
                              fontWeight='400'
                              fontSize='14px'
                              mb='4px'
                            >
                              {currentUser.email}
                            </Text>
                            <Text
                              color='#756F86'
                              fontWeight='400'
                              fontSize='12px'
                            >
                              {currentUser.role}
                            </Text>
                          </Box>
                        </Flex>
                        <Divider color='#DBE2EA' />
                        <Stack mb='24px' px='24px' py='16px'>
                          <Text
                            color='#05396B'
                            fontWeight='700'
                            fontSize='12px'
                          >
                            Phone number
                          </Text>
                          <Text
                            color='#05396B'
                            fontSize='16px'
                            fontWeight='400'
                            mb='16px'
                          >
                            {currentUser.phone}
                          </Text>
                          <Text
                            color='#05396B'
                            fontWeight='700'
                            fontSize='12px'
                          >
                            Gender
                          </Text>
                          <Text color='#05396B'>
                            {currentUser.gender || '--'}
                          </Text>
                        </Stack>
                        <Stack px='24px' my='24px'>
                          <OutlineButton
                            height='55px'
                            label='View full profile'
                            color='#05396B'
                            onClick={() => {
                              navigate('/accounts', {
                                state: {
                                  activeTab: 'my profile'
                                }
                              });
                            }}
                          />
                        </Stack>
                      </PopoverBody>
                      <Divider color='#DBE2EA' />
                      <PopoverFooter
                        px='24px'
                        pt='24px'
                        pb='46px'
                        border='none'
                        color='#756F86'
                      >
                        <Button
                          variant='link'
                          onClick={() => {
                            auth?.signOut?.();
                          }}
                        >
                          Sign out
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Box>
            </HStack>
          </Flex>
        </Grid>
      </Container>
    </Box>

    <Container
      maxW='container.xxl'
      w='100%'
      pt='30px'
      pr='0px'
      bg='gray_50'
      minH='100vh'
    >
      <Grid gridTemplateColumns='1fr 4fr' pt='108px'>
        <Box minW='250px'>
          <Box pr='30px' pos='fixed'>
            <Sidebar />
          </Box>
        </Box>
        <Box minH='60vh'>{children}</Box>
      </Grid>
    </Container>
    {/* </Box> */}
  </Box>
);
}
;

export default DashboardLayout;
