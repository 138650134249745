import { createContext } from 'react';

export interface DashboardContextInterface {
  notifications: any[];
  notificationsCount:any;
  updateNotification:(id:string)=>void
}

export const DashboardContext = createContext<DashboardContextInterface>(
  {} as DashboardContextInterface
);
