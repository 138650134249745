import React, { useEffect, useState } from 'react';
import { Box, Flex, Grid, useDisclosure, useToast } from '@chakra-ui/react';
import { Icon } from '../../components/Icons';
import { colors } from '../../theme/colors';
import { Body, Headline } from '../../components/Texts/Texts';
import Referrals from '../../components/Resources/Referrals';
import PopoverMenu from '../../components/Popover';
import ResourcesItems from '../../components/Resources/ResourcesItems';
import { getFromUrl, serverRequest } from '../../api/Api';
import ModalView from '../../components/Modal';
import { TextField } from '../../components/Inputs/TextField';
import { PrimaryButton, TextButton } from '../../components/Buttons/Buttons';

const ResourcesSection = () => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [folders, setFolders] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [folderName, setFolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = React.useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    setLoading(true);
    fetchFiles();
    fetchFolders(() => setLoading(false));
  }, []);
  const fetchFiles = () => {
    getFromUrl('resources/all/files').then((res) => {
      setFiles(res);
    });
  };
  const fetchFolders = (callback?: any) => {
    getFromUrl('resources/all/folders').then((res) => {
      setFolders(res);
      callback();
    });
  };

  const createFolder = () => {
    setIsLoading(true);
    serverRequest({
      endpoint: 'resources/folder',
      body: { folderName },
      method: 'post'
    }).then((res) => {
      setIsLoading(false);
      if (res?.data) {
        getFromUrl('resources/all/folders').then((res) => setFolders(res));
        toast({
          position: 'top',
          title: 'Success',
          description: `Folder created successfully`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error creating folder',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
      onClose();
    });
  };
  const selectFile = () => {
    fileInput.current?.click();
  };

  const onChangeFile = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const files = event.target.files;
    const formData = new FormData();
    for (const file of files) {
      formData.append('files', file);
    }
    serverRequest({
      endpoint: 'resources/upload/files',
      body: formData,
      method: 'post',
      type: 'formData'
    }).then((res) => {
      if (res?.results) {
        getFromUrl('resources/all/files').then((res) => {
          setFiles(res);
          setLoading(false);
        });
        toast({
          position: 'top',
          title: 'Success',
          description: `Files uploaded successful`,
          status: 'success',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          position: 'top',
          title: 'Error',
          description: 'Error uploading files',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      }
    });
  };

  return (
    <Grid
      gridTemplateColumns='2.4fr 1fr'
      gap='56px'
      mt='32px'
      pb='64px'
      pr='60px'
    >
      <Box>
        <PopoverMenu
          trigger={
            <Flex
              cursor='pointer'
              alignItems='center'
              justifyContent='center'
              color='deep_blue'
              mr='26px'
              bg='accent'
              w='244px'
              h='55px'
              borderRadius='12px'
              mb='36px'
              px='20px'
            >
              <Body
                color='white'
                textTransform='uppercase'
                size='14px'
                mr='26px'
                fontWeight={500}
              >
                Add new
              </Body>
              <Icon name='Upload' color={colors.white} size={20} filled />
            </Flex>
          }
          top='200px'
          w='334px'
          placement='top-start'
        >
          {(onClose: any) => (
            <Box fontSize='19px' fontWeight={500} color='gray' pt='15px'>
              <Flex
                onClick={onOpen}
                pt='30px'
                pb='14px'
                justifyContent='center'
                cursor='pointer'
                position='relative'
              >
                Create Folder
              </Flex>
              <Flex
                onClick={() => {
                  onClose();
                  selectFile();
                }}
                py='14px'
                justifyContent='center'
                cursor='pointer'
              >
                Upload File
              </Flex>
            </Box>
          )}
        </PopoverMenu>
        <Box>
          {!loading ? (
            <>
              <ResourcesItems
                type='folders'
                data={folders}
                refetch={fetchFolders}
              />
              <ResourcesItems type='files' data={files} refetch={fetchFiles} />
            </>
          ) : (
            <div className='animate-spin'></div>
          )}

          <input
            multiple={true}
            type='file'
            id='file'
            ref={fileInput}
            style={{ display: 'none' }}
            onChange={onChangeFile}
          />
        </Box>
      </Box>
      <Referrals />
      <ModalView
        isOpen={isOpen}
        onClose={onClose}
        bg='gray_50'
        maxW='600px'
        noCloseBtn
      >
        <Box bg='gray_50' px='86px' py='53px' borderRadius='8px'>
          <Headline size='19px' color='gray' mb='28px'>
            Create a new resource folder
          </Headline>
          <form onSubmit={createFolder}>
            <TextField
              label='Folder name'
              value={folderName}
              onChange={setFolderName}
              bg='white'
              required={true}
            />
            <PrimaryButton
              label='Create Folder'
              isLoading={isLoading}
              type={'submit'}
              onClick={createFolder}
            />
            <TextButton label='cancel' mt='24px' onClick={onClose} />
          </form>
        </Box>
      </ModalView>
    </Grid>
  );
};

export default ResourcesSection;
