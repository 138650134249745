import React, { useEffect, useState } from 'react';
import { DashboardContext } from './DashboardContext';
import { getFromUrl, serverRequest } from '../api/Api';

const DashboardProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = useState<any>([]);
  const [notificationsCount, setNotificationsCount] = useState<any>(0);
  useEffect(() => {
    getFromUrl('notifications').then((res) => {
      setNotifications(res);
    });
    getFromUrl('notifications/count').then((res) => {
      setNotificationsCount(res.count);
    });

  }, []);
  const updateNotification = (id:string) => {
    serverRequest({
      endpoint: `notifications/${id}`, body: { id }, method: 'put', type: 'application/json'
    }).then(data=>console.log(data));
  };

  return (
    <DashboardContext.Provider value={{ notifications, notificationsCount, updateNotification }}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
